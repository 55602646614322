   <template>
        <div class="card">
            <div class="card-header">
                <span>
                    <button class="btn btn-primary material-icons" type='button' v-if='topic && topic.order != 1' @click='orderUp(topic)'>expand_less</button>
                    <button class="btn btn-primary material-icons" type='button' v-if='topic && topic.order != count' @click='orderDown(topic)'>expand_more</button>
                </span>
                <span class="float-right" v-if='! singleTopic'>
                    <button class="btn btn-sm btn-danger" type='button' @click='removeTopic'>X</button>
                </span>
            </div>
            <div class="card-body">
                <div class="form-group" v-if='! singleTopic'>
                    <label for="'topics-tab-name-' + count"><strong>Name:<span class='red-text'>*</span></strong></label>
                    <input type="text" class="form-control" v-model='name' id="'topics-tab-name-' + count" />
                </div>
                <div class="form-group" v-if='singleTopic'>
                    <label for="'topics-tab-name-' + count"><strong>Name:<span class='red-text'>*</span></strong></label>
                    <input type="text" class="form-control" :value='trainingName' id="'topics-tab-name-' + count" disabled />
                </div>
                <div class="form-group">
                    <label for=""><strong>Description: </strong></label>
                    <input class='form-control' type="text" name="description" id="description" v-model='description'>
                </div>
                <div class="form-group">
                    <label for="'topics-tab-asset-' + count"><strong>Curriculum Asset:</strong></label>
                    <multiselect
                        v-model='curriculumAsset'
                        :options='topicAssets'
                        :multiple="false"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        :show-labels="true"
                        label="label"
                        track-by="id"
                        placeholder="Select Curriculum Asset...">
                    </multiselect>
                </div>
                <div class="form-group">
                    <label for="'topics-tab-preassessment-' + count"><strong>Pre-Assessment:</strong></label>
                    <multiselect
                        v-model='preAssessment'
                        :options='preassessments'
                        :multiple="false"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        :show-labels="true"
                        label="label"
                        track-by="id"
                        placeholder="Select PreAssessment Asset...">
                    </multiselect>
                </div>
                <div class="form-group">
                    <label for="'topics-tab-postassessment-' + count"><strong>Post-Assessment:</strong></label>
                    <multiselect
                        v-model='postAssessment'
                        :options='postassessments'
                        :multiple="false"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        :show-labels="true"
                        label="label"
                        track-by="id"
                        placeholder="Select PostAssessment Asset...">
                    </multiselect>
                </div>
                <div class="button-group mt-3">
                    <button class="btn btn-primary" @click="updateTopic" type='button' v-if='showAddButton'>Add Topic</button>
                    <button class="btn btn-primary" @click="updateTopic" type='button' v-if='! showAddButton && updated'>Update Topic</button>
                    <button class="btn btn-primary" @click="updateTopic" type='button' v-if='! showAddButton && ! updated' disabled>Update Topic</button>
                </div>
            </div>
        </div>
   </template>


    <script>
        import Multiselect from 'vue-multiselect'
        export default {
                data () {
                    return {
                        name: this.topic.name,
                        description: this.topic.description,
                        postAssessment: this.topic.postassessment,
                        preAssessment: this.topic.preassessment,
                        buttons: [
                            'bold',
                            'underline',
                            'italic',
                            'ul',
                            'ol',
                            'indent',
                            'outdent',
                            'link',
                            'source'
                        ],
                        curriculumAsset: this.topic.curriculumAsset,
                        addButtonDisabled: false,
                        updated: false,
                    }
                },
                props: {
                    count: Number,
                    postassessments: Array,
                    preassessments: Array,
                    topicAssets: Array,
                    topic: Object,
                    index: Number,
                    trainingName: String,
                    singleTopic: Boolean,
                },
                components: {
                    Multiselect
                },
                computed: {
                    showAddButton() {
                        return this.topic.name == ''
                    },
                },
                watch: {
                    name() {
                        this.updated = true;
                    },
                    description() {
                        this.updated = true;
                    },
                    postAssessment() {
                        this.updated = true;
                    },
                    preAssessment() {
                        this.updated = true;
                    },
                    curriculumAsset() {
                        this.updated = true;
                    },
                },
                methods: {
                    updateTopic() {
                        if (this.singleTopic) {
                            this.name = this.trainingName
                        }
                        this.$emit('update-topic',
                            {
                                topic: this.topic,
                                index: this.index,
                                name: this.name,
                                description: this.description,
                                postassessment: this.postAssessment,
                                preassessment: this.preAssessment,
                                curriculumAsset: this.curriculumAsset
                            }
                        )
                        this.addButtonDisabled = true
                        this.updated = false
                    },
                    removeTopic() {
                        this.$emit('remove-topic', this.topic.index)
                    },
                    orderUp(topic) {
                        this.$emit('order-up', topic)
                    },
                    orderDown(topic) {
                        this.$emit('order-down', topic)
                    }
                }
        }
    </script>
