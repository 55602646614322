<template>
    <div>
        <div class="form-group" v-if='roles.includes("Admin") || roles.includes("Program Assistant")'>
            <button class="btn btn-primary" @click.prevent='exportCDF'>Export CDF</button>
        </div>
        <div class="form-group">
            <label for="date-submitted"><strong>Date Submitted:</strong></label>
            <input type="date" name="date-submitted" id="datesubmitted" class='form-control mb-1' v-model='localSubmitted' @change='updateSubDate'>
            <label for="date-approved"><strong>Date Approved:</strong></label>
            <input type="date" name="date-approved" id="date-approved" class='form-control mb-1' v-model='localApproved' @change='updateAppDate'>
            <label for="course-number"><strong>Topic identifier / course-number:</strong><span class="red-text">*</span></label>
            <input type="text" name="course-number" id="course-number" class='form-control mb-1' v-model='localTopicIdentifier' @change='updateTopId'>
            <label for="ceus"><strong>CEUs: (Automatically calculated) </strong></label>
            <input type="text" name="ceus" id="ceus" class="form-control mb-1" v-model='ceus' disabled>
        </div>
        <div class="form-group">
            <label for="notes"><strong>Notes:</strong></label>
            <jodit-editor v-model='localNotes' @input='updateNotes' :buttons='buttons' placeholder='Enter notes...'></jodit-editor>
        </div>
    </div>
</template>

<script>
import JoditVue from 'jodit-vue'
export default {
    data() {
        return {
            localNotes: this.notes,
            localSubmitted: this.dateSubmitted,
            localApproved: this.dateApproved,
            localTopicIdentifier: this.topicIdentifier
        }
    },
    props: ['buttons', 'dateApproved', 'dateSubmitted', 'ceus', 'notes', 'trainingId', 'topicIdentifier', 'cdfInfo', 'roles'],
    methods: {
        updateSubDate() {
            this.$emit('updateSubDate', this.localSubmitted)
        },
        updateAppDate() {
            this.$emit('updateAppDate', this.localApproved)
        },
        updateTopId() {
            this.$emit('updateTopId', this.localTopicIdentifier)
        },
        updateNotes() {
            this.$emit('updateNotes', this.localNotes)
        },
        exportCDF() {
            // gather needed info for the CDF
            axios({
                url: '/training-administration/CDF/export/405/production',
                data: this.cdfInfo,
                method: 'POST',
                responseType: 'blob'
            }).then((response) => {
                console.log(response)
                const href = URL.createObjectURL(response.data)
                const link = document.createElement('a')

                link.href = href
                link.setAttribute('download', 'CourseDescriptionForm.xlsx')
                link.click()

                URL.revokeObjectURL(href)
            })
                .catch((err) => {
                })
                .finally()

            // send info to the CDF page
        }
    },
    components: {
        JoditVue
    }
}
</script>
