<template>
    <div>
        <div class="search-filters row">
            <catalog-search-filter-category-type v-for="(categoryType, index) in categoryTypes" :key="index" :category-type="categoryType" :are-many="areMany"></catalog-search-filter-category-type>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import CatalogSearchFilterCategoryType from 'components/CatalogSearchFilterCategoryType'

    export default {
        computed: {
            ...mapGetters([
                'categoryTypes'
            ]),
            areMany: function () {
                return Boolean(categoryTypes.length > 1)
            }
        },
        components: {
            CatalogSearchFilterCategoryType
        }
    }
</script>