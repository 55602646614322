<template>
    <div class="filters">
        <label class="checkbox-pacseti icon" :class='background'>
            <span class="label">{{ category.name }}</span>
            <input class='hidden-checkbox' type="checkbox" :value="category.id" :checked="isChecked" @click="filter">
        </label>
    </div>
</template>
<style>
.filters {
    /* float: left; */

}
.checkbox-pacseti > .label {
    opacity: 0;
}
.hidden-checkbox {
    opacity: 0;
    height: 40px;
    width: 40px;
}
.icon:hover {
   border-bottom: solid 5px #337ab7
}
.icon {
    align-content: center;
    height: 130px;
    width: 102px;
    display: inline-block;
}

.checked-background-1 {
    background-image: url('/images/filter-icons/FoundationalMan_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-1 {
    background-image: url('/images/filter-icons/FoundationalMan.png');
    background-repeat: no-repeat;
}
.checked-background-2 {
    background-image: url('/images/filter-icons/FoundationalRec_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-2 {
    background-image: url('/images/filter-icons/FoundationalRec.png');
    background-repeat: no-repeat;
}
.checked-background-3 {
    background-image: url('/images/filter-icons/IVDAdmin_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-3 {
    background-image: url('/images/filter-icons/IVDAdmin.png');
    background-repeat: no-repeat;
}
.checked-background-4 {
    background-image: url('/images/filter-icons/IVDDev_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-4 {
    background-image: url('/images/filter-icons/IVDDev.png');
    background-repeat: no-repeat;
}
.checked-background-5 {
    background-image: url('/images/filter-icons/PDM_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-5 {
    background-image: url('/images/filter-icons/PDM.png');
    background-repeat: no-repeat;
}
.checked-background-6 {
    background-image: url('/images/filter-icons/PDS_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-6 {
    background-image: url('/images/filter-icons/PDS.png');
    background-repeat: no-repeat;
}
.checked-background-7 {
    background-image: url('/images/filter-icons/CaseInit_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-7 {
    background-image: url('/images/filter-icons/CaseInit.png');
    background-repeat: no-repeat;
}
.checked-background-13 {
    background-image: url('/images/filter-icons/CaseMgmt_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-13 {
    background-image: url('/images/filter-icons/CaseMgmt.png');
    background-repeat: no-repeat;
}
.checked-background-11 {
    background-image: url('/images/filter-icons/Enforcement_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-11 {
    background-image: url('/images/filter-icons/Enforcement.png');
    background-repeat: no-repeat;
}
.checked-background-9 {
    background-image: url('/images/filter-icons/Establishment_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-9 {
    background-image: url('/images/filter-icons/Establishment.png');
    background-repeat: no-repeat;
}
.checked-background-10 {
    background-image: url('/images/filter-icons/Financials_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-10 {
    background-image: url('/images/filter-icons/Financials.png');
    background-repeat: no-repeat;
}
.checked-background-12 {
    background-image: url('/images/filter-icons/IntrastateIntergov_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-12 {
    background-image: url('/images/filter-icons/IntrastateIntergov.png');
    background-repeat: no-repeat;
}
.checked-background-8 {
    background-image: url('/images/filter-icons/Locate_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-8 {
    background-image: url('/images/filter-icons/Locate.png');
    background-repeat: no-repeat;
}
.checked-background-14 {
    background-image: url('/images/filter-icons/SystemDataMgmt_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-14 {
    background-image: url('/images/filter-icons/SystemDataMgmt.png');
    background-repeat: no-repeat;
}
.checked-background-19 {
    background-image: url('/images/filter-icons/BCSE_selected.png');
    background-repeat: no-repeat;
}
.unchecked-background-19 {
    background-image: url('/images/filter-icons/BCSE.png');
    background-repeat: no-repeat;
}
</style>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            category: {
                required: true,
                type: Object
            }
        },
        computed: {
            ...mapGetters([
                'search',
                'selectedCategories'
            ]),
            isChecked: function () {
                return this.selectedCategories.indexOf(this.category.id) !== -1
            },
            isActive: function () {
                return this.isChecked;
            },
            background: function() {
                if (this.isChecked) {
                    return 'checked-background-' + this.category.id;
                }
                if (! this.isChecked) {
                    return 'unchecked-background-' + this.category.id;
                }
            },

        },
        methods: {
            ...mapActions([
                'filterSearchableData'
            ]),
            ...mapMutations({
                updateSelectedCategories: 'UPDATE_SELECTED_CATEGORIES'
            }),
            filter: function (e) {
                let selectedCategories = this.selectedCategories
                if (! this.isChecked) {
                    selectedCategories.push(parseInt(e.target.value))
                } else {
                    selectedCategories.splice(this.selectedCategories.indexOf(parseInt(e.target.value)), 1)
                }
                this.updateSelectedCategories(selectedCategories)
                this.filterSearchableData()
            },
        }
    }
</script>
