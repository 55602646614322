<template>
    <!-- <div class="vue-modal-overlay">
        <div class="vue-modal">
            <h6>Confirm Cancel</h6>
            <p>Are you sure that you wish to exit this page? Any unsaved data will be lost.</p>
            <a :href="url" class="btn btn-primary">Yes</a>
            <div class="close float-right">
                <button class="btn btn-sm btn-danger" type='button'>X</button>
            </div>
        </div>
    </div> -->
    <div class="vue-modal-overlay">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Cancel</h5>
                    <button type='button' class='btn btn-danger btn-sm' @click='dismissModal'>
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you wish to leave this page?  Any unsaved changes will be lost.</p>
                </div>
                <div class="modal-footer">
                    <a :href="url" class="btn btn-primary">Leave</a>
                    <button class="btn btn-primary" type='button' @click='dismissModal'>Stay</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {

        }
    },
    props: {
        url: String
    },
    methods: {
        dismissModal() {
            this.$emit('dismissModal')
        }
    }
}
</script>
<style scoped>
    .vue-modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: #000000da;
        z-index: 1000;
    }


</style>
