<template>
    <div>
        <div class="row">
                <checkbox-group v-for="category in categories" :key="category.name" :category="category" :items="items[category.name]" @updatedChecks='updateTitles'></checkbox-group>
        </div>
    </div>
</template>

<script>
import CheckboxGroup from '../components/CheckboxGroup.vue'
export default {
    data () {
        return {
            categories: this.appinfo.jobTitleCategories,
            items: this.appinfo.jobTitleList
        }
    },
    props: ['appinfo'],
    components: {
        checkboxGroup: CheckboxGroup
    },
    computed: {
        checkedItems: function() {
            let checkedItems = []
            Object.values(this.items).forEach(item =>
                item.forEach(title => {
                    if (title.checked) {
                        checkedItems.push(title.id)
                    }
                })
            )
            this.$emit('titlesUpdated', checkedItems)
            return checkedItems
        }
    },
    mounted:function () {
        this.updateTitles();
    },
    methods: {
        updateTitles() {
            let checkedItems = []
            Object.values(this.items).forEach(item =>
                item.forEach(title => {
                    if (title.checked) {
                        checkedItems.push(title.id)
                    }
                })
            )
            this.$emit('titlesUpdated', checkedItems)
        }
    }
}
</script>
