<template>
    <div class="input-group" name='dates'>
        Effective Dates:
        <div class='col-md-3'>
            <input class="form-control"
                    autocomplete="off"
                    aria-label='Start date'
                    id="start-date"
                    name="start-date"
                    v-model="startday"
                    placeholder="MM/DD/YYYY"
                    type="text"
                    :disabled="disabled"
                    required />
            <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
            </span>
        </div>
        -
        <div class="col-md-3">
            <input class="form-control"
                    autocomplete="off"
                    aria-label='End date'
                    id="end-date"
                    name="end-date"
                    v-model="endday"
                    placeholder="MM/DD/YYYY"
                    type="text"
                    :disabled="disabled"
                    required />
            <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['startDate', 'endDate', 'disabled'],
        data () {
            return {
                startday: this.startDate,
                endday: this.endDate,
            }
        },
        mounted: function () {
            var that = this;

            $('#start-date').datepicker({
                format: 'mm/dd/yyyy',
                todayHighlight: true,
                autoclose: true,
            }).on('changeDate', function () {
                var start = $('#start-date');
                that.startday = start.val();
                start.datepicker('hide');
            });

            $('#end-date').datepicker({
                format: 'mm/dd/yyyy',
                todayHighlight: true,
                autoclose: true,
            }).on('changeDate', function () {
                var end = $('#end-date');
                that.endday = end.val();
                end.datepicker('hide');
            });
        },
    }
</script>
