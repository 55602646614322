
/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');
window.axios = require('axios');



import VTooltip from 'v-tooltip';
import Vue from 'vue';
Vue.use(VTooltip);
Vue.use(require('vue-moment'));
Vue.component('datepicker', require('./components/DatePicker.vue').default);
Vue.component('catalog-table', require('./components/CatalogTable.vue').default);
Vue.component('table-row-search-result', require('./components/TableRowSearchResult'));
Vue.component('table-row-description', require('./components/TableRowDescription.vue').default);
Vue.component('add-training-form', require('./components/AddTrainingForm.vue').default);
Vue.component('activity-order-table', require('./components/ActivityOrderTable.vue').default);
Vue.component('template-activity-order-table', require('./components/TemplateActivityOrderTable.vue').default);
Vue.component('edit-production-training-form', require('./components/EditProductionTrainingForm.vue').default);
Vue.component('edit-stage-training-form', require('./components/EditStageTrainingForm.vue').default);
Vue.component('edit-group-production-training-form',  require('./components/EditProductionGroupTrainingForm').default);

$(document).ready(function () {
    $('.multiselect__tags').on('click', function(e) {
        $(this).closest('.multiselect').focus()
    })
    if (! window.location.pathname == 'directions') {
        require('vue-multiselect')
    }
})

if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}
