<template>
    <div>
        <catalog-search-filters></catalog-search-filters>
        <b-row>
            <b-col md="9">
                <b-row>
                    <b-col md="6" offset-md="10" class="mb-4">
                        <div class="float-right">
                            <search @input="triggerUpdateQuery"></search>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div>
            <table class="table table-hover">
                <thead>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Duration</th>
                    <th>Delivery</th>
                </thead>
                <tbody>
                    <table-row-search-result v-for="(result, index) in filteredSearchableData" :key="index" :result="result" :description="result.description"></table-row-search-result>
                    <p v-if="filteredSearchableData.length === 0"> No training matches your current filters. Please adjust your filters to display training.</p>
                </tbody>
            </table>
            <b-table striped hover>
            </b-table>
        </div>
    </div>
</template>

<style>
    .table {
        margin-top: 10px;
    }
    .table thead {
        background-color: #262c3a;
        color: white;
    }
    .table .closed-plan {
        background-color: #cccccc;
    }
    button.btn-danger {
        background-color: #dc3545;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    import bTable from 'bootstrap-vue/es/components/table/table'
    import bRow from 'bootstrap-vue/es/components/layout/row'
    import bCol from 'bootstrap-vue/es/components/layout/col'
    import bCard from 'bootstrap-vue/es/components/card/card'
    import bCardBody from 'bootstrap-vue/es/components/card/card-body'
    import bLink from 'bootstrap-vue/es/components/link/link'

    import Search from './Search'

    import CatalogSearchFilters from './CatalogSearchFilters'
    import TableRowSearchResult from './TableRowSearchResult'
    export default {
        computed: {
            ...mapGetters([
                'filterGroups',
                'usefulLinks',
                'searchableData',
                'filteredSearchableData',
                'displayedSearchableData',
                'query',
                'pageSize',
                'pageNumber',
                'usefulLinks'
            ]),
        },
        components: {
            bTable,
            TableRowSearchResult,
            Search,
            bRow,
            bCol,
            bCard,
            CatalogSearchFilters
        },
        methods: {
            ...mapActions([
                'filterSearchableData'
            ]),
            ...mapMutations({
                updateQuery: 'UPDATE_QUERY',
                updatePageSize: 'UPDATE_PAGE_SIZE',
                updatePageNumber: 'UPDATE_PAGE_NUMBER',
            }),
            triggerUpdateQuery: function (query) {
                this.updateQuery(query)
                this.updatePageNumber(1)
                this.filterSearchableData()
            },
        },
        data: function () {
            return {
                items: [                   
                ],
                fields: ['Title', 'Description', 'Duration', 'Method']

            }
        }
    }
</script>