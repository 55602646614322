<template>
    <tr>
        <td>
            <a :href="uriPrefix + result.id">{{ result.name }}</a>
            <span v-if="result.cle_approved == '1'"><img src="images/pacle-logo.png"></span>
        </td>
        <td width='50%'><table-row-description :description="this.shortenDescription(result.description)"></table-row-description></td>
        <td>{{ result.length }}</td>
        <td v-if="result.deliveries" class="pt-2">
            <span v-for="(delivery, index) in result.deliveries" :key="index">
                <span v-if="delivery.id == '1'" class="material-icons" title="Face to face" style="color:#285f8f;">people</span>
                <span v-if="delivery.id == '2'" class="material-icons" title="Online" style="color:#285f8f;">language</span>
                <span v-if="delivery.id == '3'" class="material-icons" title="Webinar" style="color:#285f8f;">computer</span>
                <span v-if="delivery.id == '4'" class="material-icons" title="Video Conference" style="color:#285f8f;">videocam</span>
                <span v-if="delivery.id == '5'" class="material-icons" title="Resource" style="color:#285f8f;">local_library</span>
                <span v-if="delivery.id == '6'" title="Blended Learning">
                    <img src="images/LMS-Blended-Learning-Icon-Small-30px.svg" height="18" width="18">
                </span>
            </span>
        </td>
    </tr>
</template>

<script>
    import TableRowDescription from './TableRowDescription'
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    export default {
        data: function () {
            return {
                uriPrefix: window.uriPrefix
            }
        },
        props: {
            result: {
                required: true,
                type: Object
            },
        },
        methods: {
            shortenDescription: function (input) {
                if (input) {
                    let str = input.split(' ');
                    let strArray= [];
                    if (str.length > 30 ) {
                        for (let i = 0; i < 30; i++) {
                            strArray.push(str[i]);
                        }
                        let shortened = strArray.join(' ') + ' ... '
                        return shortened;
                    }
                }
                return input;
            },
        }
    }
</script>