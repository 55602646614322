<template>
    <div class="form" @submit='submit'>
        <form>
            <div class="buttons mb-5">
                <button class="btn btn-primary" :disabled='disableSaveButton'>Save</button>
                <button v-if='! submitted' class="btn btn-primary" @click='confirmCancel' type='button'>Cancel</button>
                <a v-if='submitted' class="btn btn-primary" href="/administration/trainingadmin">Return to List</a>
            </div>
            <cancel-confirmation v-show='showModal' :url="cancelUrl" @dismissModal='showModal = false'></cancel-confirmation>
            <error-reporter v-if='error' :errors='error' :level="level" @hide-error='hideError'></error-reporter>

            <div class="vue-modal-overlay" v-show='! verifyTopicCount'>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Define Type of Training</h5>
                        </div>
                        <div class="modal-body">
                            <p>Is this training a Multi-Topic Training (ie. SAT and NHT) or a Single Topic Training?</p>
                        </div>
                        <div class="modal-footer">
                            <button class="btn-primary btn" type='button' @click='multiTopic'>Multi-Topic</button>
                            <button v-if='!submitted' class="btn btn-primary" type='button' @click='singleTopic'>Single Topic</button>
                            <a v-if="submitted" class="btn btn-primary" type="button">Return to List</a>
                        </div>
                    </div>
                </div>
            </div>

            <p><span class="red-text">*</span> Denotes a field required when saving a new training</p>
            <label for="#trainingName"><strong>Name: <span class="red-text">*</span></strong></label>
            <input class='form-control mb-5' type="text" value='' v-model='name'>
            <ul class="nav nav-tabs" id="tabs" role='tablist'>
                <li class="nav-item" role='presentation'>
                    <a href="#summary"
                       class="nav-link"
                       id='summary-tab'
                       data-toggle='tab'
                       aria-control='summary-content'
                       aria-selected='true'
                       >
                       Summary
                    </a>
                </li>
                <li class="nav-item" role='presentation'>
                    <a href="#description"
                       class="nav-link active"
                       id='description-tab'
                       data-toggle='tab'
                       aria-control='description-content'
                       aria-selected='true'
                       >
                       Description
                    </a>
                </li>
                <li class="nav-item" role='presentation' v-show='! resourceTraining'>
                    <a href="#topics"
                       class="nav-link"
                       id='topics-tab'
                       data-toggle='tab'
                       aria-control='topics-content'
                       aria-selected='true'
                       >
                       Topics
                    </a>
                </li>
                <li class="nav-item" role='presentation'>
                    <a href="#assets"
                       class="nav-link"
                       id='assets-tab'
                       data-toggle='tab'
                       aria-control='assets-content'
                       aria-selected='true'
                       >
                       Assets
                    </a>
                </li>
                <li class="nav-item" role='presentation' v-show='! resourceTraining'>
                    <a href="#jobtitles"
                       class="nav-link"
                       id='jobtitles-tab'
                       data-toggle='tab'
                       aria-control='jobtitles-content'
                       aria-selected='true'
                       >
                       Job Titles
                    </a>
                </li>
                <li class="nav-item" role='presentation' v-show='! resourceTraining'>
                    <a href="#administrative"
                       class="nav-link"
                       id='administrative-tab'
                       data-toggle='tab'
                       aria-control='administrative-content'
                       aria-selected='true'
                       >
                       Administrative
                    </a>
                </li>
                <li class="nav-item" role='presentation' v-show='! resourceTraining'>
                    <a href="#pacle-administration"
                       class="nav-link"
                       id='pacle-administration-tab'
                       data-toggle='tab'
                       aria-control='pacle-administration-content'
                       aria-selected='true'
                       >
                       PA CLE Administration
                    </a>
                </li>
            </ul>
            <div class="tab-content pt-5" id='tabContent'>
                <div class="tab-pane  container fade" id='summary' role='tabpanel' aria-labelledby="summary-tab">
                    <div class="row">
                        <div class="col-md-3">
                            <strong>Name:</strong>
                        </div>
                        <div class="col-md-5">
                            {{ name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <strong>Abbreviation:</strong>
                        </div>
                        <div class="col-md-5">
                            {{ abbreviation }}
                        </div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3">
                            <strong>Number of topics:</strong>
                        </div>
                        <div class="col-md-5">
                            {{ topics.length }}
                        </div>
                    </div>
                    <div class="row"  v-show='! resourceTraining'>
                        <div class="col-md-3">
                            <strong>Topic names:</strong>
                        </div>
                        <div class="col-md-5">
                            <span v-for='topic in topics' :key='topic.id'>{{ topic.name }} </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <strong>Brief Overview:</strong>
                        </div>
                        <div class="col-md-5" v-html='briefOverview'>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <strong>Overview and Focus:</strong>
                        </div>
                        <div class="col-md-5" v-html='overviewAndFocus'>
                        </div>
                    </div>
                    <div class="row"  v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Topics Addressed: </strong></div>

                        <div class="col-md-5" v-html='topicsAddressed'>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><strong>Deliveries: </strong></div>

                        <div class="col-md-5">
                            <ul>
                                <li v-for='del in delivery' :key='del.id'>{{ del.name }} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row"  v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Length: </strong></div>
                        <div class="col-md-5">{{ length }}</div>
                    </div>
                    <div class="row"  v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>CEUs: </strong></div>
                        <div class="col-md-5">{{ computedCEU }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Date Submitted: </strong></div>
                        <div class="col-md-5">{{ dateSubmitted }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Date Approved: </strong></div>
                        <div class="col-md-5">{{ dateApproved }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Course Identifier: </strong></div>
                        <div class="col-md-5">{{ topicIdentifier }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Notes: </strong></div>
                        <div class="col-md-5">{{ notes }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Requestor: </strong></div>
                        <div class="col-md-5">{{ requestor.first_name }} {{ requestor.last_name }}</div>
                    </div>
                    <div class="row" v-show='! resourceTraining'>
                        <div class="col-md-3"><strong>Faculty Lead: </strong></div>
                        <div class="col-md-5"> {{ facultyLead.first_name }} {{ facultyLead.last_name }}</div>
                    </div>
                </div>
                <div class="tab-pane container fade show active" id='description' role='tabpanel' aria-labelledby="description-tab">
                    <div class="form-group">
                        <label for="description-tab-deliveries"><strong>Deliveries: <span class="red-text">*</span></strong></label>
                        <multiselect
                            v-model="delivery"
                            :options="deliveries"
                            :multiple="true"
                            :searchable="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :show-labels="true"
                            label="name"
                            track-by="name"
                            id='description-tab-deliveries'
                            placeholder="Select Delivery(ies)">
                        </multiselect>
                        <!-- <input type="hidden" name="selectedDeliveryType" :value="value.id">  -->
                    </div>

                    <div class="form-group"  v-if='blendedLearning'>
                        <label for=""><strong>Prerequisites: </strong></label>
                        <jodit-editor id='prerequisites'
                            v-model='prerequisites'
                            :buttons='buttons'
                            placeholder='Enter prerequisites...'></jodit-editor>
                    </div>

                    <div class="form-group">
                        <label for=""><strong>Abbreviation: <span class='red-text'>*</span></strong></label>
                        <input class='form-control' type="text" v-model='abbreviation'>
                    </div>
                    <div class="form-group">
                        <label for="description-tab-brief-overciew"><strong>Brief Overview: <span class="red-text">*</span></strong></label>
                        <jodit-editor id='brief-overview'
                            v-model='briefOverview'
                            :buttons='buttons'
                            placeholder='Enter brief overview..'></jodit-editor>
                    </div>
                    <div class="form-group" v-show="! resourceTraining">
                        <label for="description-tab-overview-and-focus"><strong>Overview and Focus: <span class="red-text">*</span></strong></label>
                        <jodit-editor id='overview-and-focus'
                            v-model='overviewAndFocus'
                            :buttons='buttons'
                            placeholder='Enter overview and focus...'></jodit-editor>
                    </div>
                    <div class="form-group"  v-show='! resourceTraining'>
                        <label for="description-tab-topics-addressed"><strong>Topics Addressed: <span class="red-text">*</span></strong></label>
                        <jodit-editor id='topics-addressed'
                            v-model='topicsAddressed'
                            :buttons='buttons'
                            placeholder='Enter topics addressed...'></jodit-editor>
                    </div>
                    <div class="form-group"  v-show='! resourceTraining'>
                        <label for=""><strong>Who Should Attend: <span class="red-text">*</span></strong></label>
                        <jodit-editor id='who-should-attend'
                            v-model='whoShouldAttend'
                            :buttons='buttons'
                            placeholder='Enter who should attend...'></jodit-editor>
                    </div>
                    <div class="form-group"  v-show='! resourceTraining'>
                        <label for="description-tab-length"><strong>Length: <span class="red-text">*</span></strong></label>
                        <input type="number"
                               v-model='length'
                               key="length"
                               class='form-control'
                               placeholder="Number of minutes to complete the training - NUMBER ONLY!"
                               min='0'
                               step='Any'
                        >
                    </div>
                    <div class="form-group" v-show='! resourceTraining'>
                        <label for="access"><strong>Access:</strong></label>
                        <select name="access" id="access" v-model='access' class='form-control'>
                            <option value="participant">Participant -- Normal trainings.</option>
                            <option value="public">Public -- Guest accounts can access.</option>
                        </select>
                    </div>
                    <div class="form-group"  v-show='! resourceTraining'>
                        <label for=""><strong>CEUs: (Automatically Caculated)</strong></label>
                        <input type="text"
                            v-model='computedCEU'
                            class='form-control'
                            disabled>
                    </div>
                    <div class="form-group">
                        <label for="description-tab-completion-type"><strong>Completion Type: <span class="red-text">*</span></strong></label>
                        <multiselect
                            v-model="completionType"
                            :options="completionTypes"
                            :multiple="false"
                            :searchable="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :show-labels="true"
                            label="name"
                            track-by="name"
                            id='description-tab-competion-type'
                            placeholder="Select Completion Type">
                        </multiselect>
                    </div>
                    <div class="form-group"  v-show='! resourceTraining'>
                        <label for="description-tab-learning-path"><strong>Learning Path: <span class="red-text">*</span></strong></label>
                        <multiselect
                            v-model="path"
                            :options="paths"
                            :multiple="true"
                            :searchable="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :show-labels="true"
                            label="name"
                            track-by="name"
                            id='description-tab-learning-path'
                            placeholder="Select Learning Path">
                        </multiselect>
                    </div>
                    <div class="form-group" v-show='! resourceTraining'>
                        <label for=""><strong>New Course:</strong></label>
                        <select name="new-course" id='new-course' class='form-control' v-model='newCourse'>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div class='form-group' v-show='! resourceTraining'>
                        <label for=""><strong>Revision of Existing Course:</strong></label>
                        <select name="revision-course" id='revision-course' class='form-control' v-model='revisionCourse'>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <div class='form-group' v-show='! resourceTraining'>
                        <label for=""><strong>Requestor Name:</strong></label>
                        <select name="requestor-name" id='requestor-name' class='form-control' v-model='requestor'>
                            <option v-for='faculty in faculties' :key='faculty.id' :value="faculty">{{ faculty.first_name }} {{ faculty.last_name }}</option>
                        </select>
                    </div>
                    <div class='form-group' v-show='! resourceTraining'>
                        <label for=""><strong>Faculty Lead:</strong></label>
                        <select name="faculty-lead" id='faculty-lead' class='form-control' v-model='facultyLead'>
                            <option v-for='faculty in faculties' :key='faculty.id' :value='faculty'>{{ faculty.first_name }} {{ faculty.last_name }}</option>
                        </select>

                    </div>
                    <div class="form-group" v-show='! resourceTraining'>
                        <label for="semester"><strong>Semester of Delivery:</strong></label>
                        <input type="text"
                            v-model='semester'
                            class='form-control'
                            placeholder='Semester of Delivery ...'
                            >
                    </div>
                    <div class='form-group' v-show='! resourceTraining'>
                        <label for=""><strong>Reporting Exempt: </strong><span class='material-icons' v-tooltip="reportingExemptTooltip">info</span></label>
                        <select name="reporting-exempt" id='reporting-exempt' class='form-control' v-model='reportingExempt'>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class='form-group' v-show='! resourceTraining'>
                        <label for=""><strong>Evaluation Exempt: </strong><span class="material-icons" v-tooltip="evaluationExemptTooltip">info</span></label>
                        <select name="evaluation-exempt" id='evaluation-exempt' class='form-control' v-model='evaluationExempt'>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="related-trainings"><strong>Related Trainings: </strong></label>
                        <multiselect
                            v-model="relatedTrainings"
                            :options="relatableTrainings"
                            :multiple="true"
                            :searchable="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :show-labels="true"
                            label="label"
                            track-by="name"
                            id="related-trainings"
                            placeholder="Select Related Trainings"
                        >
                        </multiselect>
                    </div>
                </div>

                <div class="tab-pane container fade" id="topics" role='tabpanel' aria-labelledby="topics-tab" v-show='! resourceTraining'>
                    <topic-tab :appinfo='appinfo' :topics='topics' @register-topic='registerTopic' @update-topic='updateTopic' @remove-topic='removeTopic' :singleTopic='singleTopicTraining' :trainingName='name'></topic-tab>
                </div>

                <div class="tab-pane container fade" id='assets' role='tabpanel' aria-labelledby="assets-tab">
                    <asset-tab :appinfo='appinfo' :assets='assets' @register-asset='registerAsset' @update-asset='updateAsset' @remove-asset='removeAsset'></asset-tab>
                </div>
                <div class="tab-pane container fade" id='jobtitles' role='tabpanel' aria-labelledby="jobtitles-tab" v-show='! resourceTraining'>
                    <job-titles-tab :appinfo='appinfo' @titlesUpdated='updateTitles'></job-titles-tab>
                </div>
                <div class="tab-pane container fade" id='administrative' role='tabpanel' aria-labelledby="administrative-tab" v-show='! resourceTraining'>
                    <administrative-tab
                        :buttons='buttons'
                        :dateApproved='dateApproved'
                        :dateSubmitted='dateSubmitted'
                        :ceus='computedCEU'
                        :topicIdentifier='topicIdentifier'
                        :notes='notes'
                        :cdfInfo='cdfInfo'
                        :roles='roles'
                        @updateSubDate='updateSubDate'
                        @updateAppDate='updateAppDate'
                        @updateTopId='updateTopId'
                        @updateCeus='updateCeus'
                        @updateNotes='updateNotes'
                    ></administrative-tab>
                </div>
                <div class="tab-pane container fade" id='pacle-administration' role='tabpanel' aria-labelledby="pacle-administration-tab" v-show='! resourceTraining'>
                     <a href="/administration/pacle-admin">PACLE Admin</a>
                </div>
            </div>
            <div class="buttons mb-5 mt-5">
                <button class="btn btn-primary" :disabled='disableSaveButton'>Save</button>
                <button class="btn btn-primary" v-if="!submitted" @click='confirmCancel' type='button'>Cancel</button>
                <a v-if="submitted" class="btn btn-primary" href="/administration/trainingadmin">Return to List</a>
            </div>
        </form>
        <hr>
        <asset-table :assetDisplay="assetDisplay"></asset-table>
    </div>
</template>

<script>
import JobTitlesTab from '../components/JobTitlesTab.vue'
import TopicTab from '../components/TopicTab.vue'
import Multiselect from 'vue-multiselect'
import { JoditEditor } from 'jodit-vue'
import AssetTab from '../components/AssetTab.vue'
import AdministrativeTab from '../components/AdministrativeTab.vue'
import ErrorReporter from '../components/ErrorReporter.vue'
import AssetTable from '../components/AssetTable.vue'
import CancelConfirmation from '../components/CancelConfirmation.vue'
import VTooltip from 'v-tooltip'
import axios from 'axios'

export default {
    data() {
        return {
            deliveries: this.appinfo.deliveries,
            paths: this.appinfo.paths,
            completionTypes: this.appinfo.completionTypes,
            assetTypes: this.appinfo.assetTypes,
            abbreviation: '',
            path: [],
            delivery: [],
            overviewAndFocus: '',
            topicsAddressed: '',
            whoShouldAttend: '',
            completionType: '',
            length: '',
            topics: [],
            assets: [],
            faculties: this.appinfo.faculties,
            requestor: '',
            facultyLead: '',
            newCourse: '',
            revisionCourse: '',
            reportingExempt: '',
            evaluationExempt: '',
            semester: '',
            briefOverview: '',
            roles: this.appinfo.roles,
            buttons: [
                'bold',
                'underline',
                'italic',
                'ul',
                'ol',
                'indent',
                'outdent',
                'link',
                'source'
            ],
            name: '',
            titles: '',
            dateSubmitted: '',
            dateApproved: '',
            topicIdentifier: '',
            ceus: this.comput,
            notes: '',
            error: '',
            level: '',
            disableSaveButton: false,
            instructions: '',
            customPage: '',
            access: 'participant',
            showModal: false,
            cancelUrl: "/administration/trainingadmin",
            verifyTopicCount: false,
            singleTopicTraining: '',
            submitted: false,
            relatedTrainings: '',
            relatableTrainings: this.appinfo.relatableTrainings,
            prerequisites: '',
        }
    },
    props: {
        appinfo: Object
    },
    components: {
        jobTitlesTab: JobTitlesTab,
        topicTab: TopicTab,
        Multiselect: Multiselect,
        JoditEditor,
        AssetTab,
        AdministrativeTab,
        ErrorReporter,
        AssetTable,
        CancelConfirmation,
        VTooltip
    },
    computed: {
        assetDisplay: function () {
            return this.assetTypes
        },
        computedCEU: function() {
            if (this.delivery[0] && this.delivery[0].name == 'Blended Learning') {
                return 0;
            }
            let cc = (this.length / 60) * 0.1
            cc = Math.trunc(cc*100)/100
            return cc
        },
        requestorName: function () {
            return this.requestor.first_name + ' ' + this.requestor.last_name
        },
        facultyLeadName: function () {
            return this.facultyLead.first_name + ' ' + this.facultyLead.last_name
        },
        cdfInfo: function() {
            let cdfInfo = {
                "newCourse" : this.newCourse,
                "revisionCourse" : this.revisionCourse,
                "courseNumber" : this.topicIdentifier,
                "abbreviation" : this.abbreviation,
                "requestor" : this.requestorName != 'undefined undefined' ? this.requestorName :  '',
                "requestorEmail" : this.requestor.email ? this.requestor.email : '' ,
                "facultyLead" : this.facultyLeadName != 'undefined undefined' ? this.facultyLeadName : '',
                "facultyLeadEmail" : this.facultyLead.email ? this.facultyLead.email : '',
                "briefOverview" : this.briefOverview,
                "outline" : this.topicsAddressed,
                "description" : this.overviewAndFocus,
                "length" : this.length,
                "ceus" : this.computedCEU,
                "courseType": this.courseType,
                "trainingName": this.name,
                "semester": this.semester,
                "briefOverview": this.briefOverview,
                "topics": this.topics.length,
            }
            return cdfInfo
        },
        courseType: function () {
            let names = []
            this.delivery.forEach(function (del){
                names.push(del.name)
            })
            return names
        },
        reportingExemptTooltip:  {
            get: function () {
                return 'Select "Yes" if information from this course is not to be be included in Annual, Quarterly, and KPI reports.'
            }
        },
        evaluationExemptTooltip:  {
            get: function () {
                return 'Select "Yes" if training should not automatically generate Feedback evaluations (Non-PACSETI content)'
            }
        },
        resourceTraining:  function () {
            if (this.delivery.length > 0) {
                return this.delivery[0].name == 'Resource'
            }
        },
        blendedLearning: function() {
            if (this.delivery.length > 0) {
                return this.delivery[0].name === 'Blended Learning'
            }
        }
    },
    methods: {
        singleTopic() {
            this.registerTopic({
                name: '',
                description: '',
                curriculumAsset: '',
                preassessment: '',
                postassessment: '',
                index: this.topics.length
            })
            this.verifyTopicCount = true
            this.singleTopicTraining = true
        },
        multiTopic() {
            this.verifyTopicCount = true
            this.singleTopicTraining = false
        },
        confirmCancel() {
            this.showModal = true
        },
        submit(e) {
            e.preventDefault()
            this.submitForm()
        },
        registerTopic(info) {
            this.topics.push(info);
        },
        hideError(info) {
            this.error = ''
        },
        updateTopic(info) {
            console.log(this.topics[info.topic.index])
            this.topics[info.topic.index].curriculumAsset = info.curriculumAsset
            this.topics[info.topic.index].description = info.description
            this.topics[info.topic.index].name = info.name
            this.topics[info.topic.index].preassessment = info.preassessment
            this.topics[info.topic.index].postassessment = info.postassessment
        },
        removeTopic (index) {
            this.topics.splice(index, 1)
        },
        registerAsset(info) {
            this.assets.push(info)
        },
        updateAsset(info) {
            this.assets[info.index].asset = info.asset
            this.assets[info.index].asset.display = info.display
            let name = info.asset.type
            if (this.assetTypes[name] > 0) {
                let localAssetTypes = this.assetTypes
                localAssetTypes[name]++
                this.$nextTick(function () {
                    this.assetTypes = localAssetTypes
                })
            } else {
                let localAssetTypes = this.assetTypes
                localAssetTypes[name] = 1
                this.$nextTick(function () {
                    this.assetTypes = localAssetTypes
                })
            }
        },
        removeAsset(index) {
            this.assets.splice(index, 1)
        },
        updateTitles(titles) {
            this.titles = titles
        },
        updateSubDate(date) {
            this.dateSubmitted = date
        },
        updateAppDate(date) {
            this.dateApproved = date
        },
        updateTopId(id) {
            this.topicIdentifier = id
        },
        updateCeus(ceu) {
            this.ceus = ceu
        },
        updateNotes(note) {
            this.notes = note
        },
        submitForm() {
            let data = {
                abbreviation: this.abbreviation,
                assets: this.assets,
                ceus: this.computedCEU,
                completionType: this.completionType,
                dateApproved: this.dateApproved,
                dateSubmitted: this.dateSubmitted,
                delivery: this.delivery,
                length: this.length,
                name: this.name,
                notes: this.notes,
                overviewAndFocus: this.overviewAndFocus,
                path: this.path,
                titles: this.titles,
                topicIdentifier: this.topicIdentifier,
                topics: this.topics,
                topicsAddressed: this.topicsAddressed,
                whoShouldAttend: this.whoShouldAttend,
                requestor: this.requestor !== '' ? this.requestor.id : '',
                facultyLead: this.facultyLead !== '' ? this.facultyLead.id : '',
                newCourse: this.newCourse,
                revisionCourse: this.revisionCourse,
                instructions: this.instructions,
                customPage: this.customPage,
                access: this.access,
                reportingExempt: this.reportingExempt,
                evaluationExempt: this.evaluationExempt,
                semester: this.semester,
                briefOverview: this.briefOverview,
                relatedTrainings: this.relatedTrainings,
                prerequisites: this.prerequisites
            }
            this.disableSaveButton = true
            const scrollToTop = () => {
                const c = document.documentElement.scrollTop || document.body.scrollTop;
                if (c > 0) {
                    window.requestAnimationFrame(scrollToTop);
                    window.scrollTo(0, c - c / 8)
                }
            }
            scrollToTop();
            axios.post('/training-administration/training/store',
                data
            ).then(
                (response) => {
                    this.error = response.data
                    this.level = 'alert-success'
                    this.submitted = true
                }
            ).catch(
                (error) => {
                    console.log(error.response.data.errors)
                    this.error = error.response.data.errors
                    this.level = 'alert-danger'
                    this.disableSaveButton = false
                }
                // create Error component that displays all errors returning
            ).finally(
            )
        }
    },

}
</script>
<style>
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
.vue-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #000000da;
    z-index: 1000;
}
</style>
