<template>
    <table class="table">
        <thead>
            <th>Type</th>
            <th>Count</th>
        </thead>
        <tbody>
                <tr v-for='count, type in displayed' :key='type'>
                    <td>{{ type }}</td>
                    <td>{{ count }}</td>
                </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: ['assetDisplay'],
    computed: {
        displayed: function () {
            let obj = {};
            for (const [key, value] of Object.entries(this.assetDisplay)) {
                if (value > 0) {
                    obj[key] = value
                }
            }
            return obj
        }
    }
}
</script>
