<template>
    <div>
        <p><strong>To reorder topics within the training, use the arrow up and arrow down buttons.</strong></p>
        <topic-card
            v-for='topic, order in topics'
            v-model='topics[order]'
            :topic='topic'
            :key='topic.name'
            :index='topic.order'
            :count='count'
            :topicAssets="appinfo.scorms"
            :preassessments='appinfo.preassessments'
            :postassessments='appinfo.postassessments'
            :trainingName='trainingName'
            :singleTopic='singleTopic'
            @update-topic='updateTopic'
            @remove-topic='removeTopic'
            @order-up='orderUp'
            @order-down='orderDown'
        ></topic-card>
        <div class="button-group mt-3" v-if='! singleTopic'>
            <button class="btn btn-primary" @click="registerTopic" type='button'>+</button>
        </div>
    </div>
</template>

<script>
import TopicCard from '../components/TopicCard.vue'
export default {
    data () {
        return {
            // count: this.topics.length
        }
    },
    props: {
        appinfo: Object,
        topics: Array,
        singleTopic: Boolean,
        trainingName: String,
    },
    components: {
        topicCard: TopicCard,
    },
    computed: {
        count () {
            return this.topics.length
        }
    },
    methods: {
        registerTopic () {
            Vue.nextTick( () =>
                this.$emit('register-topic',
                    {
                        name: '',
                        description: '',
                        postassessment: '',
                        preassessment: '',
                        ceu: '',
                        curriculumAsset: '',
                        index: this.count,
                        order: this.count + 1
                    }
                )

            )
        },
        updateTopic (info) {
            this.$emit('update-topic', info)
        },
        removeTopic (index) {
            this.$emit('remove-topic', index)
            this.topics.forEach(function (topic) {
                if (topic.index > index) {
                    topic.index--
                }
            })
        },
        orderUp(topic) {
            let topicAbove = this.topics[topic.index - 1]
            topicAbove.index = topic.index
            topicAbove.order = topic.order
            topic.index = topic.index - 1
            topic.order = topic.order - 1
            this.topics.sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
        },
        orderDown(topic) {
            let topicBelow = this.topics[topic.index + 1]
            topicBelow.index = topic.index
            topicBelow.order = topic.order
            topic.index = topic.index + 1
            topic.order = topic.order + 1
            this.topics.sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
        }
    }
}
</script>
