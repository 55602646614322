<template>
    <div class="card">
        <div class="card-header">
            Asset {{ index + 1 }}
            <a :href="'/training-administration/changelog/asset/' + asset.id" target='_blank'><span class="material-icons" title='Change Log'>track_changes</span></a>
            <span class="float-right">
                <button class="btn btn-sm btn-danger" type='button' @click='removeAsset'>X</button>
            </span>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="asset-tab-id">Asset:</label>
                <multiselect
                    v-model="asset"
                    :options="appinfo.assets"
                    :multiple="false"
                    :searchable="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :show-labels="true"
                    label="label"
                    track-by="label"
                    placeholder="Select Asset...">
                </multiselect>
            </div>
            <div class="form-group">
                <label><strong>Display: </strong></label>
                <select class='form-control' v-model='displaySetting'>
                    <option value="default">Default for asset type</option>
                    <option value="show">Show</option>
                    <option value="hide">Hidden</option>
                </select>
            </div>
            <div class="button-group">
                <button class="btn btn-primary" type='button' @click='updateAsset' v-if='showAddButton'>Add Asset</button>
                <button class="btn btn-primary" type='button' @click='updateAsset' v-if='! showAddButton && updated'>Update Asset</button>
                <button class="btn btn-primary" type='button' v-if='! showAddButton && ! updated' disabled>Update Asset</button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    data() {
        return {
            asset: this.trainingAsset.asset,
            group: 'DSMT',
            updated: false,
            displaySetting: (this.trainingAsset.asset.display !== ''  && this.trainingAsset.asset.display !== null) ? this.trainingAsset.asset.display : 'default'
        }
    },
    props: ['trainingAsset', 'appinfo', 'index'],
    components: {
        Multiselect
    },
    computed: {
        showAddButton() {
            return this.trainingAsset.asset.name == ''
        },
    },
    methods: {
        updateAsset() {
            this.$emit('update-asset',
                {
                    asset: this.asset,
                    index: this.index,
                    display: this.displaySetting,
                }
            )
            this.updated = false
        },
        removeAsset() {
                this.$emit('remove-asset', this.index)
            }
        },
        watch: {
            asset() {
                this.updated = true;
            },
            displaySetting() {
                console.log('changed')
                this.updated = true;
            }
        },
    }
</script>
