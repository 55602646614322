<template>
    <form action="">
            <div class="buttons mb-5">
                <button class="btn btn-primary" type='button'>Save</button>
                <a href='/administration/trainingadmin/' class="btn btn-primary">Cancel</a>
            </div>
            <div class="form-group">
                <label for=""><strong>Name:</strong></label>
                <input type="text" class='form-control' name="" id="" value='' v-model='name'>
            </div>
            <div class="form-group">
                <label for=""><strong>Description: </strong></label>
                <jodit-editor id='overview-and-focus'
                    v-model='description'
                    :buttons='buttons'
                    placeholder='Enter description...'></jodit-editor>
            </div>
            <div class="form-group">
                <label for=""><strong>Length: </strong></label>
                <input v-model='length' type="number" class="form-control">
            </div>
            <div class='form-group'>
                <label for=""><strong>Categories:</strong></label>
                <div v-for='category, index in groupCategories' :key='index' class='row mb-2' >
                    <div class="col-md-11">
                        <input type="text" name="" id="" v-model='category.name' class='form-control' @change='addCategory(category.name, index)'>
                    </div>
                    <div class="col-md-1">
                        <span class="float-left">
                            <button class="btn btn-sm btn-danger" type='button' @click='removeCategory(category)'>X</button>
                        </span>
                    </div>
                </div>
                <button class="btn btn-sm btn-primary" type='button' @click='addCategoryInput'>+</button>
            </div>
    </form>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { JoditEditor } from 'jodit-vue'
export default {
    data () {
        return {
            name: this.appinfo.name,
            description: this.appinfo.description,
            length: this.appinfo.length,
            groupCategories: this.appinfo.categories,
            buttons: [
                'bold',
                'underline',
                'italic',
                'ul',
                'ol',
                'indent',
                'outdent',
                'link',
                'source'
            ],
        }
    },
    props: {
        appinfo: Object
    },
    components: {
        Multiselect,
        JoditEditor
    },
    methods: {
        addCategoryInput () {
            this.groupCategories.push({'name': ''})
        },
        removeCategory (toRemove) {
            var arr = this.groupCategories
            console.log(this.groupCategories)
            this.groupCategories = arr.filter(function(item) {
                return item !== toRemove
            })
        },
        addCategory (name, index) {
            this.groupCategories[index]['name'] = name
        }
    }
}
</script>
