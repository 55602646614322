<template>
    <div class='mb-3 filter-row'>
        <div class="row ml-4">
            <div class="col-md-12 text-center h-break">
                <h3 class='ml-1' v-if="shouldDisplayName && categoryType.name !== 'Ignore'">{{ categoryType.name }}</h3>
            </div>
        </div>
        <!-- <ul class="nobullet"> -->
        <div class="row ml-4 text-center center">
            <!-- <div class="placeholder" v-if='categoryType.name == "Knowledge Area"'></div> -->
            <catalog-search-filter-category v-for="(category, index) in categoryType.categories" :key="index" :category="category"></catalog-search-filter-category>
        </div>
        <!-- </ul> -->
    </div>
</template>

<style>
    .placeholder {
        height: 118px;
        width: 25px;
        align-content: center;
    }
    .filter-row {
        align-content: center;
        width: 100%;
        justify-content: center;
    }
    .row {
        justify-content: center;
    }
</style>


<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    import CatalogSearchFilterCategory from 'components/CatalogSearchFilterCategory'

    export default {
        props: {
            categoryType: {
                required: true,
                type: Object
            },
            areMany: {
                required: true,
                type: Boolean
            }
        },
        computed: {
            ...mapGetters([
                'search',
                'selectedCategories'
            ]),
            shouldDisplayName: function () {
                return this.areMany && Boolean(this.categoryType.categories.length)
            }
        },
        components: {
            CatalogSearchFilterCategory
        }
    }
</script>
