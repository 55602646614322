<template>
    <b-form inline>
        <b-input-group>
            <b-form-input type="text" class="border-right-0 border" v-bind="$attrs" v-on="$listeners" aria-label='Search Training Catalog' placeholder='Search...'/>
            <b-input-group-addon append>
                <div class="input-group-text bg-transparent"><span class="material-icons">search</span></div>
            </b-input-group-addon>
        </b-input-group>
    </b-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    import bForm from 'bootstrap-vue/es/components/form/form'
    import bFormInput from 'bootstrap-vue/es/components/form-input/form-input'
    import bInputGroup from 'bootstrap-vue/es/components/input-group/input-group'
    import bInputGroupAddon from 'bootstrap-vue/es/components/input-group/input-group-addon'

    export default {
        mounted: function () {

        },
        components: {
            bForm,
            bFormInput,
            bInputGroup,
            bInputGroupAddon
        }
    }
    $(document).ready(function() {
        $('.form-inline').on('submit', function(e) {
            e.preventDefault();
        })
    })
</script>