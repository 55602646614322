<template>
    <div>
        <table class="table mt-2" id='table'>
            <thead>
                <tr class="table-primary">
                    <th scope='col'>Activity Name</th>
                </tr>
            </thead>
            <draggable tag='tbody' draggable='.item' 
                :list='data'
                @end='onEnd'
                class='dragging'  
                animation='150'
                chosenClass='dragged'
                ghostClass='ghost'        
                >
                <tr 
                    v-for="activity in data"
                    :key='activity.order'
                    class='item'
                    >
                    <td><span class="material-icons">sort</span>&nbsp;&nbsp;{{ activity.training.name }}</td>    
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: [
        'data',
        'training-plan-id'
    ],
    components: {
        draggable,
    },
    methods: {
        onEnd: function(e) {
            this.data.forEach((element, index) => {
                element.order = index + 1
            });
            axios.post('/training-plans/' + trainingPlanId + '/reorderactivities', this.data)
        }
    }
}
</script>
