<template>
    <div>
        <div class="alert" :class='level' role='alert'>
            <button type="button" class="close" aria-label="Close" @click='hide'>
                <span aria-hidden="true">&times;</span>
            </button>
            <span v-if='messages.length === 1'>{{ messages[0] }}</span>
            <ul v-if='messages.length > 1'>
                <li v-for='message in messages' :key='message'><strong>{{ message  }}</strong></li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
    props: ['level', 'errors'],
    methods: {
        hide() {
            this.$emit('hide-error', true)
        }
    },
    computed: {
        messages: function () {
            let statements = []
            if (typeof(this.errors) === 'string') {
                statements.push(this.errors)
            } else {
                for (const key in this.errors) {
                    if (Array.isArray(this.errors[key])) {
                        statements.push(this.errors[key][0])
                    } else if (typeof(this.errors[key]) === 'string') {
                        statements.push(this.errors[key])
                    }
                }
            }
            return statements
        }
    }
}
</script>
