<template>
    <div class="col-md-6">
        <input type="checkbox" v-model="localCategory.checked" @click="selectAll"> <strong> {{ localCategory.name }}</strong>
        <ul class="element-group-list">
            <li v-for="item in localItems" :key="item.id">
                <input
                    type="checkbox"
                    name="titles[]"
                    :value="item.id"
                    v-model='item.checked'
                    class="list-item"
                    @click="checkCategory($event)"
                > {{ item.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data () {
        return {
            localItems: this.items,
            localCategory: this.category,
        }
    },
    props: ['category', 'items'],
    mounted: function () {
    },
    methods: {
        selectAll() {
            setTimeout(() => {
                let checked = this.localCategory.checked
                this.localItems.forEach(function (item) {
                    item.checked = checked
                })
                this.$emit('updatedChecks')
            })
        },
        // uncheck Heading select all checkbox if child checkbox is unselected
        checkCategory(event) {
            setTimeout(() => {
                if (event.target.clicked == false) {
                    this.localCategory.checked = false
                }
                let allChecked = true
                this.localItems.forEach(function (item) {
                    if (item.checked == false) {
                        allChecked = false
                    }
                }, 0)
                this.localCategory.checked = allChecked
                this.$emit('updatedChecks')
            })
        },
    },
}
</script>
