<template>
    <div v-html='description'>
        {{ description }}
    </div>
</template>

<script>
export default {
    props: {
        description: {
            required: false,
            default: ' ', 
            type: String
        }
    }
}
</script>
