<template>
        <div>
            <asset-card
                v-for="asset, index in assets"
                v-model='assets[index]'
                :index='index'
                :key="asset.asset.name"
                :trainingAsset="asset"
                :appinfo="appinfo"
                @update-asset='updateAsset'
                @remove-asset='removeAsset'
                class="mt-3"
            ></asset-card>
            <button class="btn btn-primary" type='button' @click="registerAsset">+</button>
        </div>
</template>

<script>
    import AssetCard from '../components/AssetCard.vue'
    export default {
        data () {
            return {

            }
        },
        props: ['appinfo', 'assets'],
        methods: {
            registerAsset () {
                this.$emit('register-asset',
                {
                    asset: {
                        display: 'default',
                        label: '',
                        name: '',
                    },
                    index: this.count
                })
            },
            updateAsset(info) {
                info.type = this.appinfo.assets.find(x => x.id === info.asset.id).type.name
                this.$emit('update-asset', info)
            },
            removeAsset(index) {
                this.$emit('remove-asset', index)
                this.assets.forEach(function (asset) {
                    if (asset.index > index) {
                        asset.index--
                    }
                })
            },
        },
        computed: {
            count() {
                return this.assets.length
            }
        },
        components: {
            AssetCard
        }
}
</script>
